import React, { Component } from 'react'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'

import Title from '../components/page-title'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import Image from '../components/page-image-new'
//import PageOverview from '../components/page-overview';
import PageResourceSide from '../components/page-resources-side'
//import PageButtons from "../components/page-buttons";
//import { theme } from "../util/style";
import { Link } from 'gatsby'
import Image1 from '../images/resources/happy-haus-south-stradbroke-6.jpg'
const ogImages = [`https://happyhaus.com.au${Image1}`]

const PostImage = 'resources/happy-haus-south-stradbroke-6.jpg'

class Page extends Component {
  render() {
    return (
      <>
        <SEO
          title="Our Story – South Stradbroke Haus"
          description="Peter, Cheryl and their family have been holidaying at South Stradbroke Island, or “South Straddie” as it is known, for 15 years."
          images={ogImages}
        />

        <Row>
          <Flex>
            <Box width={8 / 12} px={2}>
              <Image src={PostImage} ratio={9 / 16} margin={true} />
              <Title align="left">Our Story – South Stradbroke Haus</Title>
              <Copy align="left">
                <p>
                  We talk with our client, Peter, about creating his own beach
                  house haven.
                </p>
                <p>
                  Peter, Cheryl and their family have been holidaying at South
                  Stradbroke Island, or “South Straddie” as it is known, for 15
                  years. Like most regulars to the island, the appeal is in the
                  great outdoors – the beach, boating, bushland – and the
                  casual, relaxed way of life. A place to escape from work and
                  the busyness of the mainland.
                </p>
                <p>
                  It was important that this translated to their holiday home.
                  It had to be a beach house. A house they could enjoy together,
                  and also share with family and groups of friends. Inspired by
                  a weekend to a Spicers resort with other couples, they fell in
                  love with the pavilion style design. The separation of spaces
                  offered privacy, and individual retreats within the one
                  building.
                </p>
                <p>
                  With the initial concept of a pavilion style beach house, the
                  couple approached Happy Haus to design and build their
                  getaway, and the collaboration began.
                </p>
                <p>
                  Peter explains, “throughout the whole process, we always felt
                  like the Happy Haus team were designing our house. They
                  respected our ideas, and added on them.”
                </p>
                <p>
                  “While some of the interiors, such as the VJ walls were
                  something we knew we wanted, with much of the design – colour
                  palette, the pitched roof – we took the team’s lead, and we
                  are very happy with the results.”
                </p>
                <p>
                  Arranged in three pavilion zones, each space opens onto a
                  central deck. Giving each wing the privacy of an individual
                  entry and a connection to the outdoors. With the length of the
                  building orientated North, the skillion roof and high level
                  windows have been designed to capture the natural light and
                  offer views to the sky.
                </p>
                <p>
                  If you are lucky enough to have a site with a water view, you
                  will want to make the most of this opportunity. And for the
                  Taylors, this view was orientated west. To protect the outdoor
                  entertaining area, the team designed an outdoor room. A series
                  of sliding doors, high level and layered openings, create a
                  sheltered deck space for Peter, Cheryl and their guests to
                  relax.
                </p>
                <p>
                  “We enjoyed spending last Summer and over the holidays at our
                  new house, as have our adult children. We’re definitely making
                  the most of it. We were over there just last weekend!”
                </p>
                <p>
                  From our perspective, working with Peter and Cheryl to develop
                  their pavilion concept was highly rewarding. Through the
                  process of getting to know their family, we were able to
                  design the house in a way that functioned as a highly cohesive
                  “whole” for the accommodation of large groups, while also
                  being able to “close down” in a fuss-free manner that enables
                  Pete and Cheryl to use only the master wing and family area
                  when they don’t have visitors.
                </p>
                <p>
                  The design of outdoor areas with varying degrees of shade and
                  enclosure to suit prevailing winds and solar aspect means that
                  there is always plenty of space to live, dine and relax
                  outdoors. Because of the flexibility afforded by the decks
                  that connect them, the interior layouts of the pavilions
                  themselves were able to be quite space-efficient, while
                  setting the scene for a variety of uses, depending on who is
                  staying in the house.
                </p>
                <p>
                  “As for the build, the house was delivered in a timely
                  fashion, I never felt like I was chasing up on anything”. The
                  experience was very trouble free”, says Peter.
                </p>
              </Copy>
            </Box>
            <Box width={4 / 12} px={2} pl={[0, 50]}>
              <PageResourceSide />
            </Box>
          </Flex>
        </Row>
      </>
    )
  }
}

export default Page
